/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/default.scss";
@import "./theme/darkmode.scss";

:root {
  --auth-provider-background-color: var(--ion-color-tertiary);
}

.bookcover {
  width: 30%;
  height: auto;
}

.pickerfix {
  touch-action: none;
}

.full-width {
  width: 100%;
}

.disable-action-sheet-btns {
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
}

.disable-action-sheet-btns > * {
  pointer-events: none;
}

ion-popover.wide-popover .popover-content {
  margin-left: 2% !important;
  margin-right: 2% !important;
  left: 2% !important;
  --width: 92% !important;
}

ion-popover.dark-backdrop {
  ion-backdrop {
    opacity: 50% !important;
  }
}

/* This rather ugly hack is required to stop the warning overlaying the tab bar and impacting protractor */
div.firebase-emulator-warning {
  display: none;
}

ion-list.transparrent {
  --ion-background-color: none;
  opacity: 1;
  ion-item {
    --background: none;
  }
}

ion-list.semitransparrent {
  --ion-background-color: none;
  opacity: 1;
  ion-item {
    --background: rgba(255, 255, 255, 0.6);
    @media (prefers-color-scheme: dark) {
      --background: rgba(0, 0, 0, 0.6);
    }
  }
}

div.message-area {
  padding: 7%;
  ion-text {
    font-size: larger;
    font-weight: 450;
  }
  ion-button {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

img.logo-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  max-width: 120px;
  border-radius: 20px;
}

.ion-color-toolbar {
  --ion-color-base: var(--ion-color-toolbar);
  --ion-color-base-rgb: var(--ion-color-toolbar-rgb);
  --ion-color-contrast: var(--ion-color-toolbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-toolbar-shade);
  --ion-color-tint: var(--ion-color-toolbar-tint);
}

.ion-color-text {
  --ion-color-base: var(--ion-color-text);
  --ion-color-base-rgb: var(--ion-color-text-rgb);
  --ion-color-contrast: var(--ion-color-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-totextolbar-shade);
  --ion-color-tint: var(--ion-color-text-tint);
}

.ion-color-link {
  --ion-color-base: var(--ion-color-link);
  --ion-color-base-rgb: var(--ion-color-link-rgb);
  --ion-color-contrast: var(--ion-color-link-contrast);
  --ion-color-contrast-rgb: var(--ion-color-link-contrast-rgb);
  --ion-color-shade: var(--ion-color-link-shade);
  --ion-color-tint: var(--ion-color-link-tint);
}
