@media (prefers-color-scheme: dark) {
  :root {
    /*
     * Dark Colors
     * -------------------------------------------
     */

    /** Button **/
    --ion-color-primary: #191970;
    --ion-color-primary-rgb: 25, 25, 112;
    --ion-color-primary-contrast: #00bfff;
    --ion-color-primary-contrast-rgb: 0, 191, 255;
    --ion-color-primary-shade: #161663;
    --ion-color-primary-tint: #30307e;

    /** Text **/
    --ion-color-text: #00bfff;
    --ion-color-text-rgb: 0, 191, 255;
    --ion-color-text-contrast: #191970;
    --ion-color-text-contrast-rgb: 25, 25, 112;
    --ion-color-text-shade: #00a8e0;
    --ion-color-text-tint: #1ac5ff;

    /* Ion- */
    --ion-color-link: var(--ion-color-text);
    --ion-color-link-rgb: var(--ion-color-text-rgb);
    --ion-color-link-contrast: var(--ion-color-text-contrast);
    --ion-color-link-contrast-rgb: var(--ion-color-text-contrast-rbp);
    --ion-color-link-shade: var(--ion-color-text-shade);
    --ion-color-link-tint: var(--ion-color-text-tint);

    /** Subheaders **/
    --ion-color-tertiary: #303030;
    --ion-color-tertiary-rgb: 54, 54, 54;
    --ion-color-tertiary-contrast: #00bfff;
    --ion-color-tertiary-contrast-rgb: 0, 191, 255;
    --ion-color-tertiary-shade: #202020;
    --ion-color-tertiary-tint: #404040;

    /** Header **/
    --ion-color-toolbar: #191970;
    --ion-color-toolbar-rgb: 25, 25, 112;
    --ion-color-toolbar-contrast: #ffffff;
    --ion-color-toolbar-contrast-rgb: 255, 255, 255;
    --ion-color-toolbar-shade: #161663;
    --ion-color-toolbar-tint: #30307e;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  ion-content.background-bookshelves {
    --background: black;
  }
  ion-content.background-boy-reading-1 {
    --background: black;
  }
  ion-content.background-classroom {
    --background: black;
  }
  ion-content.background-girl-reading-1 {
    --background: black;
  }
  ion-content.background-girl-reading-2 {
    --background: black;
  }

}
