/** Ionic CSS Variables **/

@import "./backgrounds/bookshelves_1_20.scss";
@import "./backgrounds/boy_reading_1_20.scss";
@import "./backgrounds/classroom_1_30.scss";
@import "./backgrounds/girl_reading_1_30.scss";
@import "./backgrounds/girl_reading_2_20.scss";

:root {
  /** primary **/
  /* Buttons */
  --ion-color-primary: #00bfff;
  --ion-color-primary-rgb: 0, 191, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00a8e0;
  --ion-color-primary-tint: #1ac5ff;

  /* Ion-Text */
  --ion-color-text: #191970;
  --ion-color-text-rgb: 25, 25, 112;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #161663;
  --ion-color-text-tint: #30307e;

  /* Ion- */
  --ion-color-link: var(--ion-color-primary);
  --ion-color-link-rgb: var(--ion-color-primary-rgb);
  --ion-color-link-contrast: var(--ion-color-primary-contrast);
  --ion-color-link-contrast-rgb: var(--ion-color-primary-contrast-rbp);
  --ion-color-link-shade: var(--ion-color-primary-shade);
  --ion-color-link-tint: var(--ion-color-primary-tint);

  /** tertiary **/
  --ion-color-tertiary: #00bfff;
  --ion-color-tertiary-rgb: 0, 191, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #00a8e0;
  --ion-color-tertiary-tint: #1ac5ff;

  /* Toolbar */
  --ion-color-toolbar: #191970;
  --ion-color-toolbar-rgb: 25, 25, 112;
  --ion-color-toolbar-contrast: #ffffff;
  --ion-color-toolbar-contrast-rgb: 255, 255, 255;
  --ion-color-toolbar-shade: #161663;
  --ion-color-toolbar-tint: #30307e;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
